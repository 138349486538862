export const confirmRemove = () => {
    return window.confirm('Bekräfta att du vill fortsätta med borttagningen');
};

export const weekdays = {
    monday: 'Måndag',
    tuesday: 'Tisdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag'
};
