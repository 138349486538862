<template>
    <router-link
        tag="tr"
        class="tableRow cursor-pointer"
        :to="to"
    >
        <slot />

        <TableCell v-if="showDelete">
            <button
                type="button"
                class="w-full block"
                @click.stop="$emit('delete')"
            >
                <DeleteIcon class="text-gray-700 hover:text-gray-500 w-8 h-8 ml-auto transition" />
            </button>
        </TableCell>
    </router-link>
</template>

<script>
import TableCell from './TableCell';
import DeleteIcon from '@/assets/images/trash.svg';

export default {
    components: {
        TableCell,
        DeleteIcon
    },

    inject: ['showDelete'],

    props: {
        to: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="postcss" scoped>
.tableRow:hover >>> td {
    @apply bg-gray-100
}
</style>
