<template>
    <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
                <slot />
            </table>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        showDelete: {
            type: Boolean,
            default: false
        }
    },

    provide () {
        return {
            showDelete: this.showDelete
        };
    }
};
</script>
