<template>
    <thead>
        <tr>
            <slot />

            <TableHeaderCell
                v-if="showDelete"
                style="width: 6.5rem"
            />
        </tr>
    </thead>
</template>

<script>
import TableHeaderCell from './TableHeaderCell';

export default {
    components: {
        TableHeaderCell
    },

    inject: ['showDelete']
};
</script>
